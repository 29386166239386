import React, { useState } from "react";
import { langVersion } from "../../accessors";
import { authorise } from "../../auth";
import { navigate } from "gatsby";

import "./LogInBox.scss";

const LogInBox = () => {

  const [user, setUser] = useState('');
  const [password, setPassword] = useState('');

  return (

    <section className="log__box">
      <div className="log-box__bg">
        <form className="form-log__container" onSubmit={
          e => {
            e.preventDefault();
            authorise(user, password);
            navigate('/admin')
          }
        }>
          <label forname="username" className="log__label">{langVersion('Nazwa użytkownika', 'Username')}</label>
          <input type="text" id="username" name="username" className="log__input" required onChange={e => setUser(e.target.value)}></input>
          <label forname="password" className="log__label">{langVersion('Hasło', 'Password')}</label>
          <input type="password" id="password" name="password" className="log__input" required onChange={e => setPassword(e.target.value)}></input>
          <input type="submit" className="log__btn" value={langVersion('Zaloguj', 'Log In')}></input>
        </form>
      </div>
    </section>
  )
}

export default LogInBox;